@import url('https://fonts.googleapis.com/css?family=Muli:200,400,700,900|Roboto:300,400,700');

@mixin sansserif() {
  font-family: 'Muli', sans-serif;
}

@mixin serif() {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #333;
}

@mixin break($point) {
  @if $point == desktop {
    @media (min-width: 1024px) { @content ; }
  }
  @else if $point == phablet {
    @media (max-width: 1024px) { @content ; }
  }
  @else if $point == tablet {
    @media (min-width: 768px) and (max-width: 1024px) { @content ; }
  }
  @else if $point == mobile {
    @media (max-width: 768px)  { @content ; }
  }
  @else if $point == mini {
    @media (max-width: 500px)  { @content ; }
  }
}

$body-color: #dfe6ec;
$black-color: #14202b;
$main-color: #1ab394;

body {
  padding: 0 0 0px 0;
  margin: 0;    
  overflow-x: hidden;
} 

h1, h2, h3, h4, h5 {
  @include sansserif();
  font-weight: 900;
  color: $black-color;
}

p {
  @include serif();
  font-size: 15px;
  line-height: 22px;
}

.main-color {
  color: $main-color;
}

.container {
  width: 1200px;
  max-width: 100%;
  display: block;
  margin: 0 auto;

  @include break(phablet) {
    box-sizing: border-box;
    padding: 0 20px;
  }
}

section {
  padding: 70px 0 90px;
}

.separator-line {
  width: 60px;
  height: 1px;
  margin: 0px auto 0;
  border: none;
  border-bottom: 2px solid $main-color;
}

form {
  position: relative;

  input, textarea, select {
    border: none;
    width: 100%;
    display: block;
    padding: 10px 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 3px;
    @include serif();
    font-style: italic;
  }

  select {
    padding: 10px 12px;
  }
  
  input[type="submit"] {
    background: $main-color;
    color: #FFF;
    font-weight: 700;
    cursor: pointer;
    transition: background 1s;
  
    &:hover {
      background: lighten($main-color, 8%);
    }
  }

  .loader {
    display: none;
  }

  .success-message {
    display: none;
  }

  &.loading {
    input, textarea, select{
      display: none;
    }

    .loader {
      display: block;
      margin: 50px auto;
    }
  }

  &.success {
    input, textarea, select{
      display: none;
    }

    .success-message{
      display: block;
      text-align: center;

      p {
        text-align: center;
      }

      .fa-check {
        display: block;
        margin: 50px auto 15px;
        font-size: 50px;
        color: $main-color;
      }
    }
  }
}

.btn {
  padding: 8px 46px;
  border: 1px solid #ccc;
  border-radius: 18px;
  color: #333;
  @include sansserif();
  font-size: 16px;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;    
  display: inline-block;
  cursor: pointer;

  span {
    position: relative;
    z-index: 1;
  }

  &:before {
    z-index: 0;
    content:'';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0px;
    height: 0%;
    background: rgba(255,255,255, 1);
    border-radius: 18px;
    transition: all 0.2s ease;
  }

  &:hover {
    &:before {
      width: 100%;
      height: 100%;
      left: 0%;
      top: 0%;
      border-radius: 30px;
    }

    color: $main-color;
  }

  &--lg {
    font-size: 20px;
    border-radius: 30px;
    padding: 13px 50px;
  }

  &--white {
    color: #FFF;
    border-color: #FFF;
  }
}

.wysiwyg {
  ul {
    list-style-type: circle;
    @include serif();
    font-size: 14px;
  }

  p {
	width: 100% !important;
    @include serif();
  }
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid $main-color; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tabs {
  &__nav {
    width: 100%;
    display: block;
    ul {
      list-style-type: none;
      display: flex;
      justify-content: center;
      padding: 0;

      li {
        display: inline-block;
        padding: 25px 40px;
        text-transform: uppercase;
        position: relative;
        @include sansserif();
        cursor: pointer;
        color: #777;

        &.active { 
          color: $black-color;

          &:after {
            content: "";
            width: 100%;
            height: 5px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: #1ab394;
          }
        }

        &:hover {
          color: $black-color;
        }
      }
    }
  }

  &__body {
    &__tab {
      width: 100%;
      display: none;

      &__title {
        width: 100%;
        display: block;
        p{
          width: 100% !important;
        }
      }

      &__content {
        display: flex;  
        flex-grow: 0;
        flex-basis: 50%;
        align-items: center;
        padding-top: 40px;

        & > * {
          width: 100%;
          box-sizing: border-box;
        }

        .wysiwyg {
          width: 50%;
          margin: auto; 

          @include break(mobile) {
            width: 100%;
          }
        }

        & > .img {
          float: left;
          width: 75%;
          margin: auto;
          img{
            max-width: 150%;
            max-height: 1000px;

          }

          @include break(mobile) {
            width: 90%;
          }
        }
      }

      &.active{
        display: block;
      }
    }
  }
}


$menu-transition-speed: .5s;

.main-navigation {
  position: fixed;
  width: 100%;
  display: block;
  padding: 0px 40px 0 0;
  box-sizing: border-box;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0);
  min-height: 60px;
  transition: background-color $menu-transition-speed;

  &__logow {
    width: 250px;
    height: 100px;
    display: block;
    background: url('../img/logow.svg') center center no-repeat;
    background-size: contain;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: all $menu-transition-speed;
  }

  &__logo {
    width: 150px;
    height: 50px;
    display: block;
    background: url('../img/logo.svg') center center no-repeat;
    position: absolute;
    top: -100px;
    left: 10px;
    transition: all $menu-transition-speed;
  }

  &__menu {
    @include sansserif();
    color: #FFF;
    float: right;
    list-style-type: none;
    padding: 0px 30px;
    margin: 0;

    & > li {
      display: inline-block;
      cursor: pointer;
      padding: 50px 15px;
      margin: 0;
      position: relative;
      transition: padding $menu-transition-speed;

      &:after {
        content: "";
        width: 100%;
        height: 5px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        transition: background-color 1s;
      }

      &.active:after, &:hover:after {
        background: $main-color;
      }
    }
  }

  .language-picker {
	  $flagSize: 24px; 
	  padding: 0;
	  width: $flagSize;
	  height: $flagSize;
	  display: inline-block;
	  overflow: hidden;
	  position: absolute;
	  top: 46px;
	  right: 20px;
	  transition: all 1s;

	  ul {
		  padding: $flagSize 0 0 0;
		  margin: 0;
		  list-style-type: none;
		  li{
			  width: $flagSize;
			  height: $flagSize;
			  padding: 0;
			  margin: 0;
			  list-style-type: none;

			  &.active{
				  position: absolute;
				  top: 0;
				  left: 0;
			  }
		  }
	  }
	  
	  &:hover{
		  height: auto;
	  }

	.flag {
		display: inline-block;
		width: $flagSize;
		height: $flagSize; 
		background: url('../img/flags.png') no-repeat; 
		background-size: $flagSize * 3 $flagSize * 2;
		opacity: 0.7;
		transition: opacity 1s;

		&-fr {
			background-position: -$flagSize * 2 0;
		}
		
		&-en {
			background-position: 0 (-$flagSize);
		}
		
		&-de {
			background-position: -$flagSize 0;
		}
		
		&-ch {
			background-position: 0 0;
		}
		
		&-it {
			background-position: -$flagSize (-$flagSize);
		}

		&:hover{
			opacity: 1;
		}
	}

	&:hover:after{
		display: none;
	}
  }

  &.small {
    background-color: rgba(255, 255, 255, 1);

    .main-navigation__logow {
      top: -100px;
    }

    .main-navigation__logo {
     top: 10px;
    }

    .main-navigation__menu {
      color: #333;
      float: right;
      list-style-type: none;
      padding: 0px 30px;
      margin: 0;
  
      & > li {
        padding: 25px 15px;
        color: $black-color;
	  }
	  
	  .language-picker{
		padding: 0;
		top: 22px;
	  }
    }
  }

  @include break(phablet){
    &.small {
      background: #FFF;

      .main-navigation__logo {
       top: 5px;
      }

      .hamburger {
        top: 10px;
        & > div {
          background-color: #14202b;
        }
      }
    }

    .main-navigation__menu {
      display: none;
      background: url('../img/landing.png') center center no-repeat;
      background-size: cover;
      width: 200px;
      height: 100%;
      display: block;
      position: fixed;
      right: -200px;
      top: 0;
      padding-top: 20px !important;
      box-sizing: border-box;
      transition: right 1s;
      box-shadow: inset 0px 1px 35px #000;

      & > li {
        width: 100%;
        color: #FFF !important;
        padding: 20px 0 !important;
        font-weight: 700;
        font-size: 14px;
        text-shadow: 1px 1px 2px #000;

        &.active {
          &:after{ display: none; }
          color: $main-color !important;
        }
	  }
	  
	  .language-picker{
      padding: 0 !important;
      position: relative;
      width: 100%;
      height: auto;
      top: auto  !important;
      right: auto !important;

      ul{
        padding: 0;

        li{
          display: inline-block;
          opacity: 0.5;
          padding-right: 5px;
    
          &.active{
            position: relative;
            top: auto;
            left: auto;
            opacity: 1;
            a{
              opacity: 1;
            }
          }
        }

      }
	  }
    }
  }

  @include break(mobile) {
    .main-navigation{
      &__logow {
        width: 190px;
        height: 85px;
      }
    }
  }
}

.main-content {
  position: relative;
  width: 100%;
  transition: margin 1s;
  background: $body-color;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 30px;
  transition: 1s;

  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 3px;
    background-color: #FFF;
    margin: 7px 0;
    transition: 0.4s;
  }

  @include break(desktop) {
    display: none;
  }
}

.menu-open {
  .main-content {
    margin-left: -200px;
    overflow: hidden;
  }

  .main-navigation__menu{
    right: 0;
  }

  .hamburger {
    right: 235px;
  
    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    .bar2 {opacity: 0;}
    .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }

  @include break(mobile) {
    .main-navigation__logo, .main-navigation__logow {
      left: -200px;
    }
  }
}

.section-hero {
  color: #FFF;
  background: url('../img/landing.png');
  height: 550px;
  width: 100%;
  display: block;
  position: relative;
  padding: 0;

  &__text {
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -140px 0 0 -480px;

    h1 {
      margin: 0;
      font-size: 56px;
      line-height: 68px;
      color: #FFF;
      font-weight: 700;

      small {
        display: block;
        font-weight: 400;
        font-size: 16px;
        margin: 15px 0 0px;
        opacity: 0.8;
        padding-left: 25px;
      }
    }

    .btn {
      margin: 5px 15px 0 0;
    }
  }

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -160px auto auto 75px;
    width: 500px;
    height: 500px;
    background: url('../img/phones.png') center center no-repeat;
    background-size: contain;
  }

  @include break(phablet) {
    &__text {
      margin: -140px 0 0 -350px;
    }

    &__img {
      margin: -160px auto auto 135px;
    }
  }

  @include break(mobile) {
    height: auto;

    &__text {
      position: relative;
      width: 100%;
      top: auto;
      left: auto;
      margin: 0;
      padding: 110px 20px 40px;
      box-sizing: border-box;
      text-align: center;

      h1 {
        font-size: 38px;
        line-height: 50px;

        small {
          padding: 15px 0;
          margin: 0;
          line-height: 24px;
        }
      }

      .btn {
        font-size: 18px;
        padding: 9px 25px;
        margin: 15px auto 0;
      }
    }

    &__img {
      display: none;
    }
  }
}

.demo-modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: all .6s;

  form {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 50px;
    background: rgba(255, 255, 255, 0.91);
    background: $body-color;
    margin: -1000px auto auto -250px;
    box-shadow: 0px 10px 30px #333;
    transition: all 1s;

    .close {
      position: absolute;
      top: 3px;
      right: 3px;
      font-size: 18px;
      color: #fff;
      text-shadow: 1px 1px 1px #999;
      cursor: pointer;

      &:hover {
        color: #ccc;
      }
    }

    h1 {
      font-size: 25px;
      text-align: center;
      margin: 5px 0 0px 0;
    }

    p {
      text-align: center;
      margin-bottom: 30px;
    }

    input, select {
      font-size: 14px;
      font-style: normal;
    }

    input[type="submit"]{
      margin-top: 30px;
    }
  }
}

.demo-modal {
  display: none;
}

.demo-modal-open-prepare {
  overflow: hidden;

  .demo-modal {
    display: block;
    opacity: 0;
    overflow: auto;

    form {
      margin-top: -1000px;
    }
  }
}

.demo-modal-open {
  .demo-modal {
    opacity: 1;

    form{
      margin-top: -300px;

      @include break(mobile) {
        max-width: 96%;
        width: 96%;
        margin: 10px 2%;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: 15px;
      }
    }
  }
}

.section-features {
  text-align: center;
  padding: 80px 0 0;

  @include break(mobile) {
    padding: 30px 0 0;
  }

  &__text-box{
    width: 33%;
    box-sizing: border-box;
    padding: 0 20px;
    display: inline-block;
    vertical-align: top;


    @include break(tablet) {
      margin: 0 5%;
    }

    @include break(mobile) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  &__img {
    margin-top: -20px;
    width: 100%;
    height: 400px;
    background: url('../img/screen-presentation-logo.png') center center no-repeat;
    background-size: contain;

    @include break(mobile) {
      height: 200px;
    }
  }
}

.section-app {
  text-align: center;
  box-sizing: border-box;
  background: #FFF;
  padding-bottom: 0px;

  &__text-wrap {
    width: 500px;
    margin: 0 auto;
    text-align: left;

    p {
      display:inline;
    }

    &__spacer{
      display:block;
      float:right;
      clear:right;
      height: 20px;

      &:nth-child(0){ width: 70px; }
      &:nth-child(1){ width: 110px; }
      &:nth-child(2){ width: 150px; }
      &:nth-child(3){ width: 190px; }
      &:nth-child(4){ width: 230px; }
      &:nth-child(5){ width: 270px; }
      &:nth-child(6){ width: 310px; }
      &:nth-child(7){ width: 350px; }
    }

    @include break(mini) {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      &__spacer {
        display: none;
      }
    }
  }

  &__img {
    margin-top: -110px;
    width: 100%;
    height: 200px;
    background: url('../img/app.png') right bottom no-repeat;
    background-size: contain;
  
    @include break(tablet) {
      margin-left: 50px;
    }
  }
}

.section-details {
  text-align: center;

  &__text-box {
    width: 33%;
    box-sizing: border-box;
    padding: 0 20px;
    display: inline-block;
    vertical-align: top;

    @include break(mobile){
      width: 100%;
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
    
    i {
      color: $main-color;
      height: 200px;
      width: 200px;
      transition: background 1s;
      // background: rgba(255, 255, 255, 0.2);
      // box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2), inset 0px -50px 100px rgba(255, 255, 255, 0.3);
      background: rgba(255, 255, 255, 0);
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.1), inset 0px 50px 30px rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      box-sizing: border-box;
      margin-bottom: -90px;
      padding-top: 63px; 
      font-size: 45px;

      &:before {
        position: relative;
        vertical-align: middle;
      }
      &.fa-eye { font-size: 45px; }
      &.fa-mobile { font-size: 50px; }
      &.fa-desktop { 
        font-size: 40px; 
        padding-top: 72px;
      }
    }

    &:hover {
      i {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.section-tabs {
  text-align: center;
  background: #FFF;

  p {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
  }

  .tabs {
    padding-top: 30px;
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;

    &__body{
      &__tab {
        &__content {
          & > .wysiwyg {
            text-align: left;
          }
        }
      }
    }

    @include break(phablet) {
      &__nav ul {
        flex-wrap: wrap;

        li{
			padding: 15px 25px;
			background: #f9f9f9;
			margin: 1px;
        }
      }

      &__body {
        &__tab {
          &__content {
            flex-direction: column;

            & > .wysiwyg {
			        margin-bottom: 30px;
            }

            & > .img {
              img { 
                max-width: 100%; 
                
                @include break(mobile) {
                  width: 140%;
                  max-width: 140%; 
                  margin-left: -20%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section-workflow {
	text-align: center;
	.wysiwyg {
		margin-top: 40px;
	}
}

.section-testimonials {
  width: 100%;
  display: block;
  background: url('../img/avatar_all.png') center center;
  position: relative;
  transition: height 1s;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #21384f;
    background: url('../img/landing.png');
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
  }

  .js-item {
		transition: all .5s;
		display: none;
		min-height: 100px;

		&.prepare {
			display: block;
			opacity: 0;
		}

		&.active {
			transform: translate(0);
			opacity: 1;
		}
  }

  &__content {
    width: 600px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;
    display: block;
    text-align: center;
    z-index: 100;
    position: relative;
    color: #FFF;

    .fa {
      color: #FFF; 
      font-size: 70px;
    }

    h4 {
      color: #FFF;
      font-weight: 700;
      font-size: 12px;
      opacity: 0.9;
      font-weight: 200;
    }

    small {
      @include sansserif();
      padding-top: 20px;
      display: block;
      font-weight: 200;
      font-size: 14px;
    }

    &__text {
      font-size: 25px;
    }
  }
}

.section-contact {
  background: url('../img/world_map.png') center top no-repeat;
  text-align: center; 

  .container {
    width: 700px;
  }

  $bg: rgba(255, 255, 255, 0.7);

  &__contact-data {
    width: 30%;
    margin: 20px 10px 0px 0px;
    display: inline-block;
    background: $bg;
    padding: 30px 0;
    border-radius: 3px;

    @include break(mobile){
      width: 100%;
    }
    
    address {
      padding: 15px 0;
      @include sansserif();
      font-size: 12px;
      line-height: 31px;
    }

    strong {
      @include serif();
      font-size: 16px;
      color: $main-color;
      font-style: normal;
    }

    a {
      color: $main-color;
      text-decoration: inherit;
    }
  }

  &__contact-form {
    width: 59%; 
    float: right;
    padding-top: 20px;

    @include break(mobile){
      width: 100%;
      float: none;
    }

	form {
		position: relative;

		input, textarea {
			border: none;
			width: 100%;
			display: block;
			padding: 10px 16px;
			margin-bottom: 10px;
			box-sizing: border-box;
			border-radius: 3px;
			@include serif();
			font-style: italic;
			background: $bg;
		}
		
		input[type="submit"] {
			background: $main-color;
			color: #FFF;
			font-weight: 700;
			cursor: pointer;
			transition: background 1s;
		
			&:hover {
				background: lighten($main-color, 8%);
			}
		}

		.loader {
			display: none;
		}

		.success-message {
			display: none;
		}

		&.loading {
			input, textarea{
				display: none;
			}

			.loader {
				display: block;
				margin: 50px auto;
			}
		}

		&.success {
			input, textarea{
				display: none;
			}

			.success-message{
				display: block;

				p {
					text-align: center;
				}

				.fa-check {
					display: block;
					margin: 50px auto 15px;
					font-size: 50px;
					color: $main-color;
				}
			}
		}
	}
  } 
}

footer {
  background: url('../img/landing.png');
  display: block;
  padding: 10px 0;

  p {
    margin: 0;
    color: #FFF;
    text-align: center;
    @include sansserif();
    font-size: 12px;
    opacity: 0.5;
  }
}